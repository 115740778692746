<template>
  <div>下载二维码</div>
</template>

<script>
export default {
  name: 'QRcode',
  data () {
    return {}
  }
}
</script>

<style scoped>
</style>
